import { AppBar, Button, Icon, IconButton, Toolbar, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import * as styles from "./Template.module.scss"

class Template extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isMenuOpen: false,
            displayGroupMenu: false,
        }
    }

    handleMenuClick() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        })
    }

    render() {
        const lightnessThemeStyle = (() => {
            if (this.props.lightnessTheme === 'dark') {
                return styles.dark
            } else if (this.props.lightnessTheme === 'light') {
                return styles.light
            } else {
                return styles.light
            }
        })()
    
        return <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            </Helmet>
            <div className={styles.main}>
                <AppBar className={`${styles.fence} ${styles.appBar} ${lightnessThemeStyle}`} color="transparent" elevation={0}>
                    <Toolbar>
                        <img className={styles.logo} src="/static/images/logo.png" />
                        <Link to="/" className={styles.title}>Daya Selaras Group</Link>
                        <div className={`${styles.menuLinkContainer} ${this.state.isMenuOpen ? styles.show : styles.hide}`}>
                            <Link to="/" className={`${styles.menuLink} ${this.props.currentPage === 'home' ? styles.currentPage : styles.notCurrentPage}`}>Home</Link>
                            <Link to="/our-story" className={`${styles.menuLink} ${this.props.currentPage === 'our-story' ? styles.currentPage : styles.notCurrentPage}`}>Our Story</Link>
                            <Link to="/what-we-do" className={`${styles.menuLink} ${this.props.currentPage === 'what-we-do' ? styles.currentPage : styles.notCurrentPage}`}>What We Do</Link>
                            <div onClick={() => {this.setState({displayGroupMenu: this.state.displayGroupMenu ? false : true})}} className={`${styles.menuLink} ${this.props.currentPage === 'group' ? styles.currentPage : styles.notCurrentPage}`}>
                                Group
                                <div className={`${styles.menuLinkGroup} ${this.state.displayGroupMenu ? styles.menuLinkGroup__show : styles.menuLinkGroup__hide}`}>
                                    <Link to="/ptdck" className={styles.menuLinkGroup__item}>Daya Cipta Kemasindo</Link>
                                    <Link to="/cks" className={styles.menuLinkGroup__item}>Cipta Kelola Selaras</Link>
                                    <Link to="/dus" className={styles.menuLinkGroup__item}>Daur Ulang Selaras</Link>
                                </div>
                            </div>                            
                            <Link to="/connect" className={`${styles.menuLink} ${this.props.currentPage === 'connect' ? styles.currentPage : styles.notCurrentPage}`}>Connect</Link>
                        </div>
                        <IconButton className={styles.menuButton} onClick={this.handleMenuClick.bind(this)}>
                            { this.state.isMenuOpen ? <Icon>close</Icon> : <Icon>menu</Icon> }
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={`${styles.content}`}>{this.props.children}</div>
                <div className={`${styles.fence} ${styles.footer}`}>
                    <div className={`${styles.footerItem}`}>
                        <Link to="https://linkedin.com/company/dayaselarasgroup" target="_blank" className={styles.footerItem__component__left}>
                            <img src="/static/images/icons/linkedin.png" className={styles.footerItemImage} />
                            <span>Daya Selaras Group</span>
                        </Link>
                        <Link to="https://www.linkedin.com/company/pt-daya-cipta-kemasindo" target="_blank" className={styles.footerItem__component__left}>
                            <img src="/static/images/icons/linkedin.png" className={styles.footerItemImage} />
                            <span>Dayacipta Kemasindo</span>
                        </Link>
                        <Link to="https://instagram.com/dayaselarasgroup" target="_blank" className={styles.footerItem__component__left}>
                            <img src="/static/images/icons/instagram.png" className={styles.footerItemImage} />
                            <span>@dayaselarasgroup</span>
                        </Link>
                    </div>
                    <div className={`${styles.footerItem}`}>
                        <div className={styles.footerItem__component__right}>Sahid Sudirman - The Office Lt.2,</div>
                        <div className={styles.footerItem__component__right}>Jl. Jend. Sudirman No.86</div>
                        <div className={styles.footerItem__component__right}>Jakarta Pusat, 10250, Indonesia</div>
                        <div className={styles.footerItem__component__right}>
                            <a href="mailto:admin@dayaselarasgroup.com" target="_blank">admin@dayaselarasgroup.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default Template
